import React from 'react';
import {useViewport} from 'react-viewport-provider';
import {DashboardLG} from './Dashboard.lg';
import {DashboardMD} from './Dashboard.md';
import {DashboardSM} from './Dashboard.sm';

export interface IProps {
  userProfile?:any;
  sidebarNavItemSelect?(eventKey?:string): void;
  getFormPostURL: string;
}

export const Dashboard = (props: IProps) => {
  const {bp} = useViewport();

  if (bp === 'xs' || bp === 'sm') {
    return <DashboardSM {...props}/>;
  }

  // if (bp === 'md') {
  //   return <DashboardMD />;
  // }

  //bp === 'lg' || bp === 'xl' || bp === 'md
  return <DashboardLG  {...props}/>;
};
