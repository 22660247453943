import { ConsoleLogger, LoggerService } from 'logger-extension-vfs';
import React, { useState, useEffect } from 'react';
import { pretty } from 'react-native-helpers';
import { Dashboard } from './components';
import Alert from 'rsuite/lib/Alert';
import {useProvDashboard} from './provider';
import { useAuth } from '../../services/auth-service/auth.provider';
import config from '../../config/default.config.json';

// const console: LoggerService = new LoggerService(
//   new ConsoleLogger('Dashboard:container'),
// );
let REVIEW_PER_PAGE = 12;

export const DashboardView = () => {
  const {TABLE_ROWS_PER_PAGE} = config;
  const [updateBatchSuccess, setUpdateBatchSuccess] = useState<boolean>(false);
  const [activeProduct, setActiveProduct] = useState<string>();
  const [userProfile, setUSerProfile] = useState<any>(null);


  const { getUserProfile } = useAuth();

  useEffect(()=>{
    getUserProfile().then(userProfile => {
      setUSerProfile(userProfile)
    });
  },[]);

  const onSideNavItemSelect = (eventKey:string) => {
    
  }
  
  const _renderBody = () => {
    return (
      <>
        <Dashboard
          userProfile={userProfile}
          sidebarNavItemSelect={onSideNavItemSelect}
          getFormPostURL={config.servers.formEP}
        />
      </>
    );
  };

  return <>{_renderBody()}</>;
};
